import React, { useState } from 'react';
import Step from './OnboardingStep';

export default function OnboardingMain() {

    // console.log(onboarding.steps);

    let [activeStep, setActiveStep] = useState(0);
    let [steps, setSteps] = useState( onboarding.steps );

    return (
        <>
            {steps.map((step, idx) => {
                return (
                    <Step
                        key={idx}
                        index={idx}
                        active={activeStep === idx}
                        background={step.background_image}
                        minHeight={ window.innerHeight }
                        type={step.type}
                        smallLogo={step.has_small_bottom_logo}
                        data={step}
                    />
                )
            })}
        </>
    )

}