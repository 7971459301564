import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import parseHTML from 'html-react-parser';

window.$ = $;

export default function OnboardingStep({index, active, background, minHeight, type, smallLogo, data}) {

    let [logoVisible, setLogoVisible] = useState(false);
    let [step2LeftWindowHeight, setStep2LeftWindowHeight] = useState(0);
    let [step2RightWindowHeight, setStep2RightWindowHeight] = useState(0);
    let [step4WindowHeight, setStep4WindowHeight] = useState(0);
    let [step10WindowHeight, setStep10WindowHeight] = useState(0);
    let [step13WindowHeight, setStep13WindowHeight] = useState(0);
    let [step15ContentHeight, setStep15ContentHeight] = useState(0);
    
    useEffect(() => {

        if( smallLogo && parseInt( smallLogo ) > 0 ) {
            setTimeout(() => {
                setLogoVisible(true);
            }, 1000);
        }

        // window.addEventListener('scroll', handleScroll());
        jQuery(window).on('resize load', function(){
            resizeElements();
        });

    }, []);

    const resizeElements = () => {
        
        switch( type ) {
            case '2':
                let imgLeft = jQuery('.step-type-2 .left-window .bg-img');
                if( imgLeft.length ) {
                    setStep2LeftWindowHeight( imgLeft.height() );
                }

                let imgRight = jQuery('.step-type-2 .right-window .bg-img');
                if( imgRight.length ) {
                    setStep2RightWindowHeight( imgRight.height() );
                }
            break;
            case '4':
                let imgWindow4 = jQuery('.step-type-4 .window .bg-img');
                if( imgWindow4.length ) {
                    setStep4WindowHeight( imgWindow4.height() );
                }
            break;
            case '10':
                let imgWindow10 = jQuery('.step-type-10 .window .bg-img');
                if( imgWindow10.length ) {
                    setStep10WindowHeight( imgWindow10.height() );
                }
            break;
            case '13':
                let imgWindow13 = jQuery('.step-type-13 .window .bg-img');
                if( imgWindow13.length ) {
                    setStep13WindowHeight( imgWindow13.height() );
                }
            break;
            case '15':
                let header15 = jQuery('.step-type-15 .header');
                let inner15  = jQuery('.step-type-15 .inner');
                if( header15.length && inner15.length ) {
                    let content15Height = inner15.height() - header15.height();
                    setStep15ContentHeight( content15Height );
                }
            break;
        }

    }

    return (
        <section className={`section onboarding-section step-type-${ type }`} style={{ backgroundImage: `url("${background.url}")`, height: `${ minHeight }px`  }}>
            { type == 1 &&
                <div className="container">
                    <img className="bg-img" src={ `${onboarding.imagesUrl}/step-1/step-1-main-window.png` } alt="Step 1 main window" />
                    <div className="inner">
                        { data.slide_main_title && <h1>{ data.slide_main_title }</h1> }
                        { data.content.subtitle && <h2>{ data.content.subtitle }</h2> }
                        { onboarding.logo && <p><img src={onboarding.logo} className="step-1-logo" width="353" height="122" alt="Logo" /></p> }
                    </div>
                </div>
            }
            { type == 2 &&
                <>
                    <div className="container">
                        <div className="left-window" style={{ height: step2LeftWindowHeight ? `${step2LeftWindowHeight}px` : 'auto' }}>
                            <img className="bg-img" src={ `${onboarding.imagesUrl}/step-2/step-2-left-window.png` } alt="Step 2 left window" />
                            <div className="inner">
                                { parseHTML( data.content.content ) }
                            </div>
                        </div>
                        <div className="right-window" style={{ height: step2RightWindowHeight ? `${step2RightWindowHeight}px` : 'auto' }}>
                            <img className="bg-img" src={ `${onboarding.imagesUrl}/step-2/step-2-right-window.png` } alt="Step 2 right window" />
                            <div className="inner">
                                { data.slide_main_title && <h1>{ data.slide_main_title }</h1> }
                            </div>
                        </div>
                        <div className="bottom-graphic">
                            <img src={ `${onboarding.imagesUrl}/step-2/step-2-bottom-graphic.png` } alt="Bottom graphic" />
                        </div>
                    </div>
                </>
            }
            { type == 3 &&
                <div className="container">
                    <div className="left-window">
                        <div class="inner">
                            { data.slide_main_title && <h1>{ data.slide_main_title }</h1> }
                            { data.content.subtitle && <h2>{ data.content.subtitle }</h2> }
                        </div>
                    </div>
                    <div className="right-window">
                        <div class="inner">
                            { data.content.goal_1 &&
                            <div class="list-item list-item-1">
                                <p>{ data.content.goal_1 }</p>
                            </div>
                            }
                            { data.content.goal_2 &&
                            <div class="list-item list-item-2">
                                <p>{ data.content.goal_2 }</p>
                            </div>
                            }
                            { data.content.goal_3 &&
                            <div class="list-item list-item-3">
                                <p>{ data.content.goal_3 }</p>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            }
            { type == 4 &&
                <div className="container">
                    { data.slide_main_title && <h1>{ data.slide_main_title }</h1> }
                    <div className="inner">
                        { data.content.contents_1 && 
                        <div className="window window-1" style={{ height: step4WindowHeight ? `${step4WindowHeight}px` : '232px' }}>
                            <img className="bg-img" src={ `${onboarding.imagesUrl}/step-4/step-4-window.png` } alt="Step 4 window 1" />
                            <div className="title">01</div>
                            <div className="inner-window">
                                { data.content.contents_1 }
                            </div>
                        </div>
                        }
                        { data.content.contents_2 && 
                        <div className="window window-2" style={{ height: step4WindowHeight ? `${step4WindowHeight}px` : '232px' }}>
                            <img className="bg-img" src={ `${onboarding.imagesUrl}/step-4/step-4-window.png` } alt="Step 4 window 2" />
                            <div className="title">02</div>
                            <div className="inner-window">
                                { data.content.contents_2 }
                            </div>
                        </div>
                        }
                        { data.content.contents_3 && 
                        <div className="window window-3" style={{ height: step4WindowHeight ? `${step4WindowHeight}px` : '232px' }}>
                            <img className="bg-img" src={ `${onboarding.imagesUrl}/step-4/step-4-window.png` } alt="Step 4 window 3" />
                            <div className="title">03</div>
                            <div className="inner-window">
                                { data.content.contents_3 }
                            </div>
                        </div>
                        }
                        { data.content.contents_4 && 
                        <div className="window window-4" style={{ height: step4WindowHeight ? `${step4WindowHeight}px` : '232px' }}>
                            <img className="bg-img" src={ `${onboarding.imagesUrl}/step-4/step-4-window.png` } alt="Step 4 window 4" />
                            <div className="title">04</div>
                            <div className="inner-window">
                                { data.content.contents_4 }
                            </div>
                        </div>
                        }
                        { data.content.contents_5 && 
                        <div className="window window-5" style={{ height: step4WindowHeight ? `${step4WindowHeight}px` : '232px' }}>
                            <img className="bg-img" src={ `${onboarding.imagesUrl}/step-4/step-4-window.png` } alt="Step 4 window 5" />
                            <div className="title">05</div>
                            <div className="inner-window">
                                { data.content.contents_5 }
                            </div>
                        </div>
                        }
                        { data.content.contents_6 && 
                        <div className="window window-6" style={{ height: step4WindowHeight ? `${step4WindowHeight}px` : '232px' }}>
                            <img className="bg-img" src={ `${onboarding.imagesUrl}/step-4/step-4-window.png` } alt="Step 4 window 6" />
                            <div className="title">06</div>
                            <div className="inner-window">
                                { data.content.contents_6 }
                            </div>
                        </div>
                        }
                    </div>
                </div>
            }
            { type == 5 && 
                <div className="container">
                    <div className="inner">
                        <div className="header">
                            <div className="three-dots"></div>
                            { data.slide_main_title && <h1>{ data.slide_main_title }</h1> }
                        </div>
                        <div className="content">
                            { data.content.subtitle && <h2>{ data.content.subtitle }</h2> }
                            { data.content.content && <>{ parseHTML( data.content.content ) }</> }                            
                        </div>
                    </div>
                </div>
            }
            { type == 9 && 
                <div className="container">
                    <div className="inner">
                        <div className="header">
                            <div className="three-dots"></div>
                            { data.slide_main_title && <h1>{ data.slide_main_title }</h1> }
                        </div>
                        <div className="content">
                            <div className="left-window">
                                { data.content.subtitle && <h2>{ data.content.subtitle }</h2> }
                                { data.content.left_window &&
                                    <div class="left-window-content">
                                        <img className="bg-img" src={ `${onboarding.imagesUrl}/step-9/step-9-left-window.png` } alt="Step 9 left window" />
                                        <p>{ data.content.left_window }</p>
                                    </div>
                                }
                            </div>
                            <div className="right-window">
                                { data.content.right_window_top &&
                                    <div class="right-window-content right-window-top-content">
                                        <img className="bg-img" src={ `${onboarding.imagesUrl}/step-9/step-9-right-window.png` } alt="Step 9 right window" />
                                        <p>{ data.content.right_window_top }</p>
                                    </div>
                                }
                                { data.content.right_window_bottom &&
                                    <div class="right-window-content right-window-bottom-content">
                                        <img className="bg-img" src={ `${onboarding.imagesUrl}/step-9/step-9-right-window.png` } alt="Step 9 right window" />
                                        <p>{ data.content.right_window_bottom }</p>
                                    </div>
                                }
                            </div>                       
                        </div>
                    </div>
                </div>
            }
            { type == 10 && 
                <div className="container">
                    <div className="inner">
                        <div className="header">
                            <div className="three-dots"></div>
                            { data.slide_main_title && <h1>{ data.slide_main_title }</h1> }
                        </div>
                        <div className="content">
                            <div className="window left-window" style={{ height: step10WindowHeight ? `${step10WindowHeight}px` : 'auto' }}>
                                <img className="bg-img" src={ `${onboarding.imagesUrl}/step-10/step-10-window.png` } alt="Step 10 right window" />
                                <div className="window-content">
                                    <div className="header">
                                        <div className="three-dots"></div>
                                        { data.content.left_title && <h3>{ data.content.left_title }</h3> }
                                    </div>
                                    { data.content.left_content &&
                                        <>
                                            { parseHTML( data.content.left_content ) }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="window right-window" style={{ height: step10WindowHeight ? `${step10WindowHeight}px` : 'auto' }}>
                                <img className="bg-img" src={ `${onboarding.imagesUrl}/step-10/step-10-window.png` } alt="Step 10 right window" />
                                <div className="window-content">
                                    <div className="header">
                                        <div className="three-dots"></div>
                                        { data.content.right_title && <h3>{ data.content.right_title }</h3> }
                                    </div>
                                    { data.content.right_content &&
                                        <>
                                            { parseHTML( data.content.right_content ) }
                                        </>
                                    }
                                </div>
                            </div>                      
                        </div>
                    </div>
                </div>
            }

            { type == 13 && 
                <div className="container">
                    <div className="inner">
                        { data.slide_main_title && <h1>{ data.slide_main_title }</h1> }
                        <div className="windows">
                            <div className="window left-window">
                                <div className="header">
                                    <div class="three-dots"></div>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li className="orange-light">Formatting</li>
                                        <li className="orange-bold">KeyVision</li>
                                        <li className="green">Holidays</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="window right-window">
                                <div className="header">
                                    <div class="three-dots"></div>
                                </div>
                                <div className="right-window-content" style={{ height: step13WindowHeight ? `${step13WindowHeight}px` : 'auto' }}>
                                    <img className="bg-img" src={ `${onboarding.imagesUrl}/step-13/step-13-window.png` } alt="Step 13 window" />
                                    { data.content.content && 
                                    <div className="content">
                                        { parseHTML( data.content.content ) }
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            { type == 14 && 
                <div className="container">
                    <div className="inner">
                        <div className="header">
                            <div className="three-dots"></div>
                            { data.slide_main_title && <h1>{ data.slide_main_title }</h1> }
                        </div>
                        <div className="content">
                            { data.content.subtitle && <h2>{ data.content.subtitle }</h2> }
                            <div className="windows">
                                { data.content.content_1.content &&
                                <div className="window window-1">
                                    { data.content.content_1.title && <h3>{ data.content.content_1.title }</h3> }
                                    <div className="content">
                                        { parseHTML( data.content.content_1.content ) }
                                    </div>
                                </div>
                                }
                                { data.content.content_2.content &&
                                <div className="window window-2">
                                    { data.content.content_2.title && <h3>{ data.content.content_2.title }</h3> }
                                    <div className="content">
                                        { parseHTML( data.content.content_2.content ) }
                                    </div>
                                </div>
                                }
                                { data.content.content_3.content &&
                                <div className="window window-3">
                                    { data.content.content_3.title && <h3>{ data.content.content_3.title }</h3> }
                                    <div className="content">
                                        { parseHTML( data.content.content_3.content ) }
                                    </div>
                                </div>
                                }
                                { data.content.content_1.content &&
                                <div className="window window-4">
                                    { data.content.content_4.title && <h3>{ data.content.content_4.title }</h3> }
                                    <div className="content">
                                        { parseHTML( data.content.content_4.content ) }
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            { type == 15 && 
                <div className="container">
                    <div className="inner">
                        <div className="header">
                            <div className="three-dots"></div>
                            { data.slide_main_title && <h1>{ data.slide_main_title }</h1> }
                        </div>
                        <div className="content" style={{ height: step15ContentHeight ? `${step15ContentHeight}px` : 'auto' }}>
                            { data.content.subtitle && <h2>{ data.content.subtitle }</h2> }
                            <div className="windows">
                                { data.content.content_1 &&
                                <div className="window window-1">
                                    <div className="header">
                                        <img className="dot-img" src={ `${onboarding.imagesUrl}/dot-red.png` } alt="dot-red" />
                                    </div>
                                    <div className="content">
                                        { parseHTML( data.content.content_1 ) }
                                    </div>
                                </div>
                                }
                                { data.content.content_2 &&
                                <div className="window window-2">
                                    <div className="header">
                                        <img className="dot-img" src={ `${onboarding.imagesUrl}/dot-red.png` } alt="dot-red" />
                                    </div>
                                    <div className="content">
                                        { parseHTML( data.content.content_2 ) }
                                    </div>
                                </div>
                                }
                                { data.content.content_3 &&
                                <div className="window window-3">
                                    <div className="header">
                                        <img className="dot-img" src={ `${onboarding.imagesUrl}/dot-red.png` } alt="dot-red" />
                                    </div>
                                    <div className="content">
                                        { parseHTML( data.content.content_3 ) }
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            { logoVisible && <a href={ onboarding.siteUrl } target="_blank" rel="noreferrer noopener" className="small-logo"><img className="dot-img" src={ `${onboarding.imagesUrl}/bottom-small-logo.jpg` } alt="bottom-small-logo" /></a> }
        </section>
    );

}