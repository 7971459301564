import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import Onboarding from './components/OnboardingMain';
import ReactFullpage from '@fullpage/react-fullpage';

export default function App() {

    jQuery('body').on('change', '.pewc-form-field', function(evt){
        if( jQuery('.pewc-total-field').text() != '0,00 lei' ) {
            jQuery('.wooc-price-amount.amount.amount-free').text( jQuery('.pewc-total-field').text() );
        } else {
            jQuery('.wooc-price-amount.amount.amount-free').text( 'Utilizati filtrele de mai jos pentru calcularea pretului' );
        }
    });

    useEffect(() => {
        
        setInterval(() => {
            if( onboarding.uid ) {
                jQuery('#user_id').val( onboarding.uid );
            };
        }, 3000);
    
        jQuery('body').on('click', '.quiz-submit', function(evt) {

            let answersMap = [0, 1, 0, 1, 0, 1, 0, 1, 0, 1];

            jQuery('.quiz-feedback').remove();
    
            jQuery('.quiz-question').each(function(idx, el) {
    
                let answer = jQuery(el).find('input[type="radio"]:checked').parents('.wpcf7-list-item').index();
                let correct = false;

                if( answer == answersMap[ idx ] ) {
                    correct = true;
                }
    
                jQuery(this).find('.answers').after('<div class="quiz-feedback" style="color: ' + (correct == true ? 'green': 'red') + '">' + (correct == true ? 'Correct!': 'Incorrect!') + '</div>');

            });
    
        });

    }, []);

    let [steps, setSteps] = useState( onboarding.steps );

    const anchors = onboarding.steps.map((step, idx) => {
        return step.slide_main_title;
    });

    // return <Onboarding />
    return <ReactFullpage
        //fullpage options
        licenseKey={'YOUR_KEY_HERE'}
        scrollingSpeed={1000}
        navigation={true}
        navigationPosition={'right'}
        navigationTooltips={anchors}
        showActiveTooltip={true}
        render={({ state, fullpageApi }) => {
        return (
            <ReactFullpage.Wrapper>
                <Onboarding />
            </ReactFullpage.Wrapper>
        );
        }}
    />

}

const root = ReactDOM.createRoot(document.getElementById('mpr-onboarding'));
root.render(
  <App />
);